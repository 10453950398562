.pf-subheader-smb-menu--container {
    padding-top: 48px !important;
    width: 100vw;
    display: flex;
}

.pf-subheader-smb-left-menu--container {
    flex: 0 0 65%;
}

.pf-subheader-menu--title {
    color: #212529;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 7px;
}

.pf-subheader-menu--desc {
    color: #212529;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 27px;
}

.pf-subheader-menu--card-container {
    display: flex;
    justify-content: left;
}

.pf-subheader-menu--card-container > a {
    color: #212529;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #d7d7d7;
    flex: 0 0 calc(25% - 10px);
    box-sizing: border-box;
    cursor: pointer;
    margin-right: 10px;
}

.pf-subheader-menu--card-container > a:hover,
.pf-subheader-menu--card-container > .pf-subheader-menu--card--active {
    background-color: #f2f2f2;
}

.pf-subheader-menu--card-icon {
    width: 56px;
    height: 56px;
    background-color: #f2f2f2;
    border-radius: 100%;
    margin-bottom: 14px;
    background-size: 100%;
    background-repeat: no-repeat;
}

.pf-subheader-menu--card-container
    > a:first-child
    > .pf-subheader-menu--card-icon {
    background-image: url('../../../assets/images/business/subheader/smb/2bcom-online-Service-icon-1.svg');
}

.pf-subheader-menu--card-container
    > a:first-child:hover
    > .pf-subheader-menu--card-icon,
.pf-subheader-menu--card-container
    > .pf-subheader-menu--card--active:first-child
    > .pf-subheader-menu--card-icon {
    background-image: url('../../../assets/images/business/subheader/smb/2bcom-online-Service-icon-1_hover.svg');
}

.pf-subheader-menu--card-container
    > a:nth-child(2)
    > .pf-subheader-menu--card-icon {
    background-image: url('../../../assets/images/business/subheader/smb/2bcom-online-Service-icon-2.svg');
}

.pf-subheader-menu--card-container
    > a:nth-child(2):hover
    > .pf-subheader-menu--card-icon,
.pf-subheader-menu--card-container
    > .pf-subheader-menu--card--active:nth-child(2)
    > .pf-subheader-menu--card-icon {
    background-image: url('../../../assets/images/business/subheader/smb/2bcom-online-Service-icon-2_hover.svg');
}

.pf-subheader-menu--card-container
    > a:nth-child(3)
    > .pf-subheader-menu--card-icon {
    background-image: url('../../../assets/images/business/subheader/smb/2bcom-online-Service-icon-3.svg');
}

.pf-subheader-menu--card-container
    > a:nth-child(3):hover
    > .pf-subheader-menu--card-icon,
.pf-subheader-menu--card-container
    > .pf-subheader-menu--card--active:nth-child(3)
    > .pf-subheader-menu--card-icon {
    background-image: url('../../../assets/images/business/subheader/smb/2bcom-online-Service-icon-3_hover.svg');
}

.pf-subheader-menu--card-container
    > a:nth-child(4)
    > .pf-subheader-menu--card-icon {
    background-image: url('../../../assets/images/business/subheader/smb/2bcom-online-Service-icon-4.svg');
}

.pf-subheader-menu--card-container
    > a:nth-child(4):hover
    > .pf-subheader-menu--card-icon,
.pf-subheader-menu--card-container
    > .pf-subheader-menu--card--active:nth-child(4)
    > .pf-subheader-menu--card-icon {
    background-image: url('../../../assets/images/business/subheader/smb/2bcom-online-Service-icon-4_hover.svg');
}

.pf-subheader-menu--card-container
    > a:last-child
    > .pf-subheader-menu--card-icon {
}

.pf-subheader-menu--card-container
    > a:last-child:hover
    > .pf-subheader-menu--card-icon,
.pf-subheader-menu--card-container
    > .pf-subheader-menu--card--active:last-child
    > .pf-subheader-menu--card-icon {
}

.pf-subheader-menu--card-title {
    color: #212529;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 6px;
}

.pf-subheader-menu--card-desc {
    color: #212529;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 10px;
}

.pf-subheader-smb-right-menu--container {
    margin-left: 84px;
}

.pf-subheader-menu--demostore-menu-container {
    border-radius: 6px;
    padding: 4px 6px 5px 12px;
    border: 1px solid #d7d7d7;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.pf-subheader-menu--demostore-menu-container:hover {
    background-color: #fdf2f6;
}

.pf-subheader-menu--demostore-menu-container:last-child {
    margin-bottom: 0;
}

.pf-subheader-menu--demostore-menu-icon {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background-size: 100%;
}

.pf-subheader-menu--demostore-menu-label {
    flex: 0 0 calc(100% - 110px);
    margin-left: 17px;
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    color: #212529 !important;
}

.pf-subheader-menu--desc2 {
    margin-bottom: 19px;
}

.not-show {
    visibility: hidden;
}

@media screen and (max-width: 1023px) and (min-width: 601px) {
    .pf-subheader-smb-menu--container {
        display: block;
    }

    .pf-subheader-smb-right-menu--container {
        margin-left: 0;
        margin-top: 30px;
    }
}

@media screen and (max-width: 600px) {
    .pf-subheader-smb-menu--container {
        display: block;
        background-color: #ffffff;
        padding: 29px 15px !important;
    }

    .pf-subheader-menu--title {
        margin-bottom: 25px;
    }

    .pf-subheader-menu--card-desc,
    .pf-subheader-menu--desc {
        display: none;
    }

    .pf-subheader-smb-right-menu--container {
        margin-left: 0;
    }

    .pf-subheader-menu--card-container {
        display: block;
    }

    .pf-subheader-menu--card-container > a {
        display: flex;
        align-items: center;
        padding: 4px 12px;
        margin-bottom: 8px;
    }

    .pf-subheader-menu--card-icon {
        margin-bottom: 0;
        width: 46px;
        height: 46px;
        margin-right: 16px;
    }

    .pf-subheader-menu--card-title,
    .pf-subheader-menu--demostore-menu-label {
        margin-bottom: 0;
        font-size: 1.1rem;
    }

    .smb-menu--divider {
        width: 100%;
        height: 1px;
        background-color: #d7d7d7;
        margin: 30px 0 25px;
    }

    .not-show {
        display: none !important;
    }
}
